import React, { ReactElement, useState } from 'react'
import { Link, navigate } from 'gatsby'

import { Button, Grid, P, Wrapper } from '@farewill/ui'
import { BREAKPOINT } from '@farewill/ui/tokens'

import BreakpointSwitch from 'components/BreakpointSwitch'
import { Form } from 'components/Form'
import AddressForm from 'components/Form/AddressInput'
import Input from 'components/Form/Input'
import MarketingConsentField from 'components/Form/MarketingConsentField'

import {
  email as emailValidation,
  phoneNumber as phoneNumberValidation,
} from 'lib/form/validation'
import { FUNERAL_PLAN, PRODUCTS_MAP } from 'lib/products/constants'
import { trackBingProspect, trackFuneralPlanAdConvertion } from 'lib/tracking'

import PATHS from 'paths'
import ErrorMessage from 'views/cremation/components/ErrorMessage'
import { createErrorMessage } from 'views/cremation/utils'

import sendCallRequest from '../../api/sendCallRequest'
import { ContactValues } from '../../types'
import {
  CONTACT_DETAILS_ADDRESS_CITY,
  CONTACT_DETAILS_ADDRESS_COUNTRY_CODE,
  CONTACT_DETAILS_ADDRESS_LINE_ONE,
  CONTACT_DETAILS_ADDRESS_LINE_TWO,
  CONTACT_DETAILS_ADDRESS_LOOKUP,
  CONTACT_DETAILS_ADDRESS_POSTAL_CODE,
  CONTACT_DETAILS_EMAIL,
  CONTACT_DETAILS_MARKETING_CONSENT_REJECTED,
  CONTACT_DETAILS_NAME,
  CONTACT_DETAILS_PHONE_NUMBER,
} from '../../constants'

const initialFormValues = {
  [CONTACT_DETAILS_NAME]: '',
  [CONTACT_DETAILS_EMAIL]: '',
  [CONTACT_DETAILS_ADDRESS_LOOKUP]: '',
  [`${CONTACT_DETAILS_ADDRESS_LOOKUP}.search`]: '',
  [CONTACT_DETAILS_ADDRESS_LINE_ONE]: '',
  [CONTACT_DETAILS_ADDRESS_LINE_TWO]: '',
  [CONTACT_DETAILS_ADDRESS_CITY]: '',
  [CONTACT_DETAILS_ADDRESS_COUNTRY_CODE]: '',
  [CONTACT_DETAILS_ADDRESS_POSTAL_CODE]: '',
  [CONTACT_DETAILS_PHONE_NUMBER]: '',
}

const SliceForm = ({
  guideType,
}: {
  guideType: 'email' | 'post'
}): ReactElement | null => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [apiError, setApiError] = useState<string>('')

  const submitButtonLabel =
    guideType === 'email' ? 'Email my guide' : 'Send my guide'

  const handleSubmit = async (values: ContactValues) => {
    if (isSubmitting) return

    setIsSubmitting(true)
    setApiError('')

    let address
    const lineOne = values[CONTACT_DETAILS_ADDRESS_LINE_ONE]
    const lineTwo = values[CONTACT_DETAILS_ADDRESS_LINE_TWO]
    const postalCode = values[CONTACT_DETAILS_ADDRESS_POSTAL_CODE]
    const countryCode = values[CONTACT_DETAILS_ADDRESS_COUNTRY_CODE]
    const city = values[CONTACT_DETAILS_ADDRESS_CITY]

    if (lineOne && postalCode && countryCode && city) {
      address = {
        lineOne,
        ...(lineTwo && { lineTwo }),
        postalCode,
        countryCode,
        city,
      }
    }

    try {
      const { data } = await sendCallRequest({
        name: values[CONTACT_DETAILS_NAME],
        email: values[CONTACT_DETAILS_EMAIL],
        phoneNumber: values[CONTACT_DETAILS_PHONE_NUMBER],
        product: PRODUCTS_MAP[FUNERAL_PLAN].backstageEnum,
        interestedInProducts: [PRODUCTS_MAP[FUNERAL_PLAN].backstageEnum],
        marketingConsents: {
          general: values[CONTACT_DETAILS_MARKETING_CONSENT_REJECTED]
            ? 'unsubscribed'
            : 'subscribed',
        },
        sourceIdentifier: address
          ? 'postal_brochure_order'
          : 'online_brochure_order',
        ...(address && { address }),
      })
      const { type } = data.data

      if (type) {
        trackFuneralPlanAdConvertion()
        trackBingProspect({ category: 'funeral_plans' })
        navigate(PATHS.CREMATION.FUNERAL_PLAN_GET_A_GUIDE_CALLBACK, {
          state: {
            guideType,
          },
        })
      } else {
        setApiError('Something went wrong, please try again later')
      }
    } catch (error) {
      if (error instanceof Error) {
        const errorMessage = createErrorMessage(error)
        setApiError(
          errorMessage || 'Something went wrong, please try again later'
        )
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Wrapper>
      <Form initialValues={initialFormValues} onSubmit={handleSubmit}>
        <Grid>
          {apiError && (
            <Grid.Item>
              <ErrorMessage message={apiError} />
            </Grid.Item>
          )}

          <Grid.Item>
            <Input
              name={CONTACT_DETAILS_NAME}
              label="Your name *"
              placeholder="For example, Jane Smith"
              type="text"
              required
            />
          </Grid.Item>

          <Grid.Item>
            <Input
              name={CONTACT_DETAILS_EMAIL}
              label={`Your email ${guideType === 'email' ? '*' : '(optional)'}`}
              type="email"
              validate={emailValidation}
              required={guideType === 'email'}
            />
          </Grid.Item>
          {guideType === 'post' && (
            <AddressForm
              name={CONTACT_DETAILS_ADDRESS_LOOKUP}
              type="text"
              label="Address"
              required
            />
          )}
          <Grid.Item>
            <Input
              name={CONTACT_DETAILS_PHONE_NUMBER}
              type="text"
              label="Your phone number *"
              validate={phoneNumberValidation}
              required
            />
          </Grid.Item>

          <Grid.Item>
            <MarketingConsentField />
          </Grid.Item>

          <Grid.Item>
            <P size="S">
              By continuing, you agree to us getting in touch about our funeral
              plans by email and phone. We’ll always follow our{' '}
              <Link to={PATHS.GENERAL.PRIVACY}>privacy policy</Link>.
            </P>
          </Grid.Item>
          <Grid.Item>
            <BreakpointSwitch
              breakAt={BREAKPOINT.S}
              aboveComponent={
                <Button.Primary loading={isSubmitting} type="submit" wide>
                  {submitButtonLabel}
                </Button.Primary>
              }
              belowComponent={
                <Button.Primary loading={isSubmitting} type="submit" stretch>
                  {submitButtonLabel}
                </Button.Primary>
              }
            />
          </Grid.Item>
        </Grid>
      </Form>
    </Wrapper>
  )
}

export default SliceForm
